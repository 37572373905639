<template>
  <ModalLayout>
    <template v-slot:main>
      <div class="content">
        <check-icon />
        <label class="title">{{ t('schedule_tour.review_title_thankyou') }}</label>
      </div>
    </template>
  </ModalLayout>
</template>

<script>
import useI18n from '@/composables/useI18n';
import ModalLayout from '@/components/Shared/ModalLayout.vue'
import CheckIcon from '@/components/Shared/CheckIcon.vue'

export default {
  name: 'ScheduleTourFinish',

  components: {
    ModalLayout,
    CheckIcon
  },
  setup(){
    const { t } = useI18n();
    return { t };
  },
}
</script>

<style scoped>
.content {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  margin-top: 64px;
}

</style>
