<template>
    <div
        class="dropdown"
        :class="{
            'dropdown--expanded': expanded,
            'dropdown--transparent': transparent,
        }"
        @click="toggleDropdown"
    >
        <div class="dropdown__selected">
            {{ selectedValue }}
            <vue-feather
                class="dropdown__arrow"
                type="chevron-down"
                size="20"
                stroke="#F4EDFF"
            />
        </div>
        <XyzTransition xyz="fade front duration-2">
            <div
                v-if="expanded"
                class="dropdown__menu"
            >
                <ul>
                    <li
                        v-for="item in options"
                        :key="item.key"
                        class="dropdown__item"
                        :class="{
                            'dropdown__item--selected': item.key === modelValue,
                        }"
                        @click="selectOption(item)"
                    >
                        {{ item.value }}
                    </li>
                </ul>
            </div>
        </XyzTransition>
    </div>
</template>

<script>
import feather from "feather-icons";

export default {
    props: {
        modelValue: {
            type: [String, Number],
            required: true,
        },

        options: {
            type: Array,
            default: () => [],
        },

        placeholder: {
            type: String,
            default: 'Select item',
        },

        transparent: {
            type: Boolean,
            default: false,
        }
    },

    emits: ['update:modelValue'],

    data() {
        return {
            expanded: false,
        };
    },

    computed: {
        selectedValue() {
            return this.options.find(item => item.key === this.modelValue)?.value ?? this.placeholder;
        },
    },

    mounted() {
        feather.replace();
        document.addEventListener("click", this.handleClickOutside);
    },

    beforeUnmount() {
        document.removeEventListener("click", this.handleClickOutside);
    },

    methods: {
        selectOption(option) {
            this.$emit('update:modelValue', option.key);
        },

        toggleDropdown() {
            this.expanded = !this.expanded;
        },

        handleClickOutside(e) {
            if (!this.$el.contains(e.target)) {
                this.expanded = false;
            }
        },
    }
}
</script>

<style scoped>
.dropdown {
    z-index: 50;
}
.dropdown__selected {
    display: flex;
    justify-content: space-between;
    padding: 4px 8px 4px 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #F4EDFF;
    border-radius: 16px;
    border: 1px solid #B692F6;
    background: #150E20;
    cursor: pointer;
}
.dropdown--transparent .dropdown__selected {
    border: none;
    background: transparent;
    border-radius: 0;
}
.dropdown__arrow {
    margin-left: 4px;
    transition: all 0.3s ease;
}
.dropdown--expanded .dropdown__arrow {
    transform: rotate(180deg);
}

.dropdown__menu {
    position: absolute;
    margin-top: 8px;
    padding: 12px 32px 12px 12px;
    border-radius: 16px;
    background: #201632;
    box-shadow: 0px -12px 48px 0px rgba(0, 0, 0, 0.25);
}

.dropdown__item {
    display: flex;
    padding: 8px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    list-style-type: none;
    white-space: nowrap;
    cursor: pointer;
}

.dropdown__item:before {
    content: "\25CF";
    font-size: 20px;
    line-height: 20px;
    color: #5D4B7F;
    padding: 0 16px 0 8px;
}

.dropdown__item--selected:before {
    color: #B383FF;
}
</style>