import actions from "./actions";
import intro_modal from "./intro_modal";
import video_window from "./video_window";
import schedule_tour from "./schedule_tour";

export default {
  actions,
  intro_modal,
  video_window,
  schedule_tour,

  chat: {
    history_menu_top_card: "Menu",
  },
  neighborhood: {
    sort_by: "Sort by",
    switch_to: {
      list: "switch to list",
      map: "switch to map",
    },
  },
  floorplans: {
    availability: {
      title: "Availability",
      description: "Current units based on preferences",
    },
    form_label_square_feet: "Square Feet (Optional)",
  },
  preference: {
    title: "Select Preference",
  },
  bedrooms: {
    long: {
      1: 'One bedroom',
      2: 'Two bedrooms',
      3: 'Three bedrooms',
      4: 'Four bedrooms',
      5: 'Five bedrooms',
      6: 'Six bedrooms',
      7: 'Seven bedrooms',
      8: 'Eight bedrooms',
      9: 'Nine bedrooms',
      10: 'Ten bedrooms',
    }
  },
  form_one_left_schedule_tour: "We need some basic information",
  date_top_right_tour: "Choose a date",
  time_not_found: "Not availble times",
  time_top_right_tour: "Choose a time",
  menu_headline: "What Do You Want To Explore?",
  contact_form_top_card: "Contact",
  button_add_feedback: "add feedback",
  button_details: "add details",
  button_add_to_calendar: "add to calendar",
  results: "Results",
};
