import { createStore } from 'vuex'
import main from './modules/main'
import i18n from './modules/i18n'
import user from './modules/user'
import sdk from './modules/sdk'
import map from './modules/map'
import conversation from './modules/conversation'
import menu from './modules/menu'

export default createStore({
    modules: {
        main,
        i18n,
        user,
        sdk,
        map,
        conversation,
        menu
    },
    state: {
        agentSelected: 0,
    },
})