/**
 * Get object property by path string (e.g. 'a', 'a.b.c', etc)
 * @example
 * ```js
 * const obj ={a: {b: {c: 'value'}}};
 * const cPropData = getProperty(obj, 'a.b.c');
 * // cPropData will be equal to 'value'
 * ```
 * @param {object} obj tagret object to get property from
 * @param {string} path key path separated by dots (.)
 * @returns formatted price string
 */
export function getProperty(obj = {}, path = "") {
  if (typeof path !== "string" || !isObject(obj)) {
    return;
  }

  return path.split(".").reduce((acc, pathKey) => {
    if (!acc || isObject(acc) && !Object.hasOwn(acc, pathKey)) {
      return;
    }

    return acc[pathKey];
  }, obj);
}

/**
 * Set object property by path string (e.g. 'a', 'a.b.c', etc)
 * @example
 * ```js
 * const obj ={a: {b: {c: 'value'}}};
 * setProperty(obj, 'a.b.c', 'new value');
 * // obj.a.b.c will be equal to 'new value'
 * ```
 * @param {object} obj object to mutate
 * @param {string} path key path separated by dots (.)
 * @param {string} new value
 * @returns updated object
 */
export function setProperty(obj = {}, path = "", value) {
  if (typeof path !== "string" || !isObject(obj)) {
    return;
  }

  const keys = path.split('.');
  const last = keys.pop();

  keys.forEach(function(key) {
    if (!Object.hasOwn(obj, key)) {
      obj[key] = {};
    }
    obj = obj[key];
  })

  obj[last] = value;
  return obj;
}

/**
 * Checks if passed variable is Object {}
 * @param {*} value Target value to check
 */
export const isObject = (value) => value?.constructor === Object;
