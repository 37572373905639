
import { ref, computed } from "vue";

export default function useSort(items, initialValue = null) {
  const sortBy = ref(initialValue);

  const sortedItems = computed(() => {
    return [...items.value].sort((a, b) => {
      switch (sortBy.value) {
        case "rating":
          return b.rating - a.rating;
        case "alphabetically":
          return a.name.localeCompare(b.name);
        default:
          return 0;
      }
    });
  });

  return { sortBy, sortedItems };
}