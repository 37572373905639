import { getProperty } from "@/utils/object";
import TranslationService from "@/services/translation.service";
import { LocalesCodes } from "@/constants/locales";

export default {
  namespaced: true,

  state: {
    translations: null,
    locale: LocalesCodes.EN,
  },

  getters: {
    locale(state) {
      return state.locale;
    },
    translate: (state, getters) => (key) => {
      const keyPath = `${getters.locale}.${key}`;
      const translation = getProperty(state.translations, keyPath);

      if (!translation) {
        console.warn("i18n: Missing translation for " + keyPath);
        return "";
      }
      
      return translation;
    },
  },

  actions: {
    async setLocale({ commit }, locale) {
      commit("setLocale", locale);
    },
    async loadTranslations({ commit }) {
      const translations = await TranslationService.loadTranslations();
      commit("loadTranslations", translations);
    },
  },

  mutations: {
    setLocale(state, locale) {
      state.locale = locale;
    },

    loadTranslations(state, translations) {
      state.translations = translations;
    },
  },
};
