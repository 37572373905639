<!-- Displays the footer in the onboarding component -->
<template>
  <div class="footer">
    <div id="footer-row">
      Powered by: 
      <img
        class="center"
        style="width:48px" 
        src="@/assets/quext_logo.jpg"
        alt="Quext"
      />
    </div>

    <div id="footer-row">
      <img
        class="center"
        style="width: 33px"
        src="@/assets/fair_housing.png"
        alt="Fair Housing"
        />
        <a 
          href="/privacy-policy.html" 
          target="_blank" 
          class="text-link">{{ this.t('intro_modal.persona_select_button_privacy_policy') }}
        </a><span style="width:16px;">|</span>
        <a
          href="/browser-compatibility.html"
          target="_blank" 
          class="text-link">{{ this.t('intro_modal.persona_select_button_browser_compatibility') }}
        </a>
    </div>

    <div id="footer-row">
      <span> © {{ year }} Quext, LLC.</span>
    </div>

  </div>
</template>

<script>
import useI18n from '@/composables/useI18n';

export default {
  name: 'MainFooter',

  setup(){
    const { t } = useI18n();
    return { t };
  },

  computed: {
    year() {
      return new Date().getFullYear()
    },
  },
}
</script>

<style scoped>
.footer {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  display: flex;
  flex-direction: column;
  bottom: 0;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: var(--black);
  margin-bottom: 10px;
  /* margin-top: 100px; */
  /* position: absolute; */
}

.footer .center{
  margin: auto 5px;
  display: inline-block;
}

.text-link{
  color: var(--secondary-color);
}

#footer-row{
  display: flex;
  align-items: center;
}

@media only screen and (max-height: 700px) {
  .footer {
    margin-top: 16px;
    position: relative;
  }
}
</style>
