/**
 * Stores all the labels for common actions 
 */

export default {
  done: "Done",
  select: "Select",
  submit: "Submit",
  back: "Back",
  next: "Next",
  skip: "Skip",
  cancel: "Cancel",
  review: "Review",
  edit_search: "Edit search",
  clear: "Clear",
};