/**
 * Stores all the labels for common actions
 */

export default {
  done: "Terminar",
  select: "Seleccionar",
  submit: "Enviar",
  back: "Atrás",
  next: "Siguiente",
  skip: "Saltar",
  cancel: "Cancelar",
  review: "Revisar",
  edit_search: "Editar búsqueda",
  clear: "Borrar",
};
