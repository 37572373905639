/**
 * Video window
 */

export default {
  schedule_tour: "Agendar una cita",
  infotip:
    "Simplemente hable con el humano digital para iniciar la conversación. También puede hacer click/tocar o escribir, ¡la elección es suya!",
  chat_placeholder: "Has una pregunta...",
};
