import MapService from '@/services/map.service';

export default {
    namespaced: true,

    state: {
        schools: '',
    },

    getters: {
        getSchools(state) {
            return state.schools;
        },
    },

    actions: {
        async fetchSchools({ commit }, communityUUID) {
            let schools = await MapService.getSchools(communityUUID);
            commit('setSchools', schools);
        },
    },

    mutations: {
        setSchools(state, schools) {
            state.schools = schools;
        },
    }
}