export default {
  form_label_desired_number_bedrooms: "Bedrooms",
  form_label_max_budget: "Price Range",
  form_subtitle_fill_out_this_form:
    "A few items to get things started",
  title_basic_info: "Basic info",
  label_phone: "Phone Number (Optional)",
  required: "required*",
  required_field: "This field is required",
  invalid_email: "This email is invalid",
  invalid_phone: "Invalid phone",
  placeholder_phone: "Enter your phone",
  review_title_review_details: "Review Details",
  review_title_appointment_summary:
    "Submit the information below",
  review_label_date_time: "Scheduled Time",
  review_label_location: "Location",
  review_label_movein_date: "Move-in Date",
  review_label_contact_details: "Contact Details",
  review_label_preferences: "Bedroom Preference",
  review_label_price_range: "Price Range",
  review_title_feedback: "Feedback",
  review_subtitle_feedback: "Add your comments below",
  review_title_appointment_confirmation: "Appointment Confirmed!",
  review_title_appointment_review:
    "How would you rate your experience?",
  review_title_thankyou: "Thank You for your Feedback!",
};