<template>
  <ModalLayout>
    <template v-slot:title>
      <span class="content-title">{{
        t('contact_form_top_card')
      }}</span>
    </template>
    <template v-slot:main>
      <label class="subtitle">{{
        t('form_one_left_schedule_tour')
      }}</label>
      <div class="content space">
        <text-input-squared
          id="name"
          :borderTop="true"
          required
          :error="this.errors.includes('name')"
          :value="this.form.name"
          @update:value="updateForm('name', $event)"
          placeholder="first name"
          :messageError="t('schedule_tour.required_field')"
        />
        <text-input-squared
          id="lastname"
          required
          :error="this.errors.includes('lastname')"
          :value="this.form.lastname"
          @update:value="updateForm('lastname', $event)"
          :messageError="t('schedule_tour.required_field')"
          placeholder="last name"
        />
        <text-input-squared
          id="email"
          required
          :error="this.errors.includes('email')"
          :value="this.form.email"
          @update:value="updateForm('email', $event)"
          placeholder="email"
          :messageError="
            this.errors.includes('email') && this.form.email.length > 0
              ? t('schedule_tour.invalid_email')
              : t('schedule_tour.required_field')
          "
        />
        <text-input-squared
          id="phone"
          class="space"
          required
          type="text"
          :borderBottom="true"
          :error="this.errors.includes('phone')"
          :value="this.formatPhoneNumber(this.form.phone)"
          @update:value="updatePhone"
          :placeholder="t('schedule_tour.placeholder_phone')"
          :max="14"
          :messageError="
            this.errors.includes('phone') && this.form.phone.length > 0
              ? t('schedule_tour.invalid_phone')
              : t('schedule_tour.required_field')
          "
        />
      </div>
    </template>
  </ModalLayout>
  <container-buttons
    :textLeft="t('actions.back')"
    :onClickLeft="() => this.onClickPrev()"
    :onClickRight="() => this.submit()"
    :textRight="t('actions.next')"
  />
</template>

<script scoped>
import useI18n from '@/composables/useI18n';
import TextInputSquared from '@/components/Shared/TextInputSquared.vue'
import ModalLayout from '@/components/Shared/ModalLayout.vue'
import ContainerButtons from '@/components/Shared/ContainerButtons.vue'

export default {
  name: 'FormTwo',
  components: {
    TextInputSquared,
    ModalLayout,
    ContainerButtons,
  },

  setup(){
    const { t } = useI18n();
    return { t };
  },

  props: {
    onClickReview: Function,
    onClickPrev: Function,
    onClickNext: Function,
    form: {
      name: String,
      lastname: String,
      email: String,
      phone: String,
      errors: Array,
    },
  },
  data() {
    return {
      errors: [],
    }
  },
  methods: {
    updatePhone($event) {
      this.updateForm('phone', $event)
    },
    formatPhoneNumber(phoneNumber) {
      // Strip all non-numeric characters from the input
      let newNumber = phoneNumber?.replace(/\D/g, '')
      // Apply formatting to the phone number based on its length
      if (newNumber?.length == 0) {
        return
      }
      if (newNumber?.length <= 3) {
        return '(' + newNumber
      }
      if (newNumber?.length <= 6) {
        return '(' + newNumber.substr(0, 3) + ') ' + newNumber.substr(3)
      }
      return (
        '(' +
        newNumber?.substr(0, 3) +
        ') ' +
        newNumber?.substr(3, 3) +
        '-' +
        newNumber.substr(6)
      )
    },
    updateForm(key, event) {
      this.$emit('update-form', key, event)
    },
    validateFields() {
      const emailRegex = /\S+@\S+\.\S+/ // Regex to match email format
      if (!this.form.name || !this.form.name.trim()) this.errors.push('name')
      if (!this.form.lastname || !this.form.lastname.trim())
        this.errors.push('lastname')
      if (!this.form.email || !emailRegex.test(this.form.email))
        this.errors.push('email')
      if (!this.form.phone) this.errors.push('phone')
      if (this.form.phone) {
        if (/[a-zA-Z]/.test(this.form.phone) || this.form.phone.length < 14) {
          this.errors.push('phone')
        }
      }
    },
    submit() {
      this.errors = []
      this.validateFields()
      if (this.errors.length === 0) {
        this.onClickReview()
      }
    },
  },
}
</script>

<style scoped>
hr {
  border-top: 1px solid var(--secondary-color);
}

.content {
  display: flex;
  flex-direction: column;
  height: 33em;
}

.input-container {
  display: flex;
  flex-direction: column;
}

input {
  padding: 10px;
  border: 1px solid #ccc;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
}

.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: var(--tertiary-color);
  margin-bottom: 26px;
  margin-left: 24px;
}

.space {
  margin-bottom: 30px;
}

.required {
  width: 266px;
  height: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: var(--white);
}

.modals-container {
  text-align: left;
  color: #ffffff;
  height: 100%;
  position: relative;
}

.button-container {
  width: 100%;
  display: flex;
  background-color: var(--primary-color);
}
.left {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
.right {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
.button-container > div {
  width: 46%;
}

.slider {
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-top: 1px solid var(--secondary-color) !important;
  border-left: 1px solid var(--secondary-color);
  border-right: 1px solid var(--secondary-color);
  border-bottom: 1px solid var(--secondary-color) !important;
  cursor: pointer;
  font-size: 12px;
}

.content-title {
  font-size: 25px;
}

@media only screen and (max-height: 700px) {
  .content {
    height: 24em;
  }
}
</style>
