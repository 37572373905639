import { isValidNumber } from "@/utils/number";

/**
 * Convert bedroom number to the corresponding BE key
 * @param {number | string} count bedroom count
 * @returns bedroom count label
 */
export function numberToBedroomCount(count = 0) {
  if (!isValidNumber(count) || count < 0 || count > 9) {
    return "";
  }

  const numbersMap = {
    0: "ZERO",
    1: "ONE",
    2: "TWO",
    3: "THREE",
    4: "FOUR",
    5: "FIVE",
    6: "SIX",
    7: "SEVEN",
    8: "EIGHT",
    9: "NINE",
  };

  return numbersMap[count] + "_BEDROOM";
}

/**
 * Formatter for slider tooltip
 * @param {number | string} price
 * @returns formatted price string
 */
export function formatPrice(price = 0) {
  return `$${Math.round(price)}`;
}
