import fallbackImage from "@/assets/fallback.jpg";

/**
 * Load background image for an HTML element. In case of unsuccessful fetch fallback image will be used.
 * @param {HTMLElement} target target HTML element to set background image
 * @param {String} imageUrl image URL
 */
export function loadBackgroundImage(target, imageUrl = "") {
  if (!target || !imageUrl) {
    return;
  }

  const img = new Image();
  const getCssRule = (url) => `url(${url}) no-repeat center / cover`;

  img.onload = function () {
    target.style.background = getCssRule(imageUrl);
  };

  img.onerror = function () {
    target.style.background = getCssRule(fallbackImage);
  };

  img.src = imageUrl;
}
