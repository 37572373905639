/**
 * Check if passed value represents number value, either explicitly or implicitly
 * @param {string | number} value
 * @returns
 */
export function isValidNumber(value) {
  return (
    typeof value === "number" ||
    (typeof value === "string" && !isNaN(Number(value)))
  );
}
