<template>
  <ModalLayout>
    <template #title>
      {{ t('preference.title') }}
    </template>

    <template #main>
      <div class="content">
        <div class="bed-container">
          <div class="wrapper-price-label space">
            <img src="@/assets/Icons/BedFrontIcon.svg" />
            <span class="price-label">
              {{
        t(
          "schedule_tour.form_label_desired_number_bedrooms"
        )
      }}
            </span>
          </div>
          <MultiButton :selectedItems="bedrooms" @update:selectedItems="updateForm('bedrooms', $event)"
            :options="availableBeds" />
        </div>

        <div class="input-container">
          <div class="wrapper-price-label space">
            <img src="@/assets/Icons/DollarIcon.svg" />
            <span class="price-label">
              {{ t("schedule_tour.form_label_max_budget") }}
            </span>
          </div>
          <div class="field">
            <Slider :value="form.budgetRange || priceRangeData" :min="priceRangeData[0]" :max="priceRangeData[1]"
              :step="STEP" :format="formatPrice" class="slider-purple" @update="updateForm('budgetRange', $event)"
              :disabled="disableAction" />
          </div>
        </div>

        <div class="input-container">
          <div class="wrapper-price-label space">
            <img src="@/assets/Icons/Square1Icon.svg" />
            <span class="price-label">
              {{ t("floorplans.form_label_square_feet") }}
            </span>
          </div>
          <div class="field">
            <Slider :value="form.feetRange || feetRangeData" :min="feetRangeData[0]" :max="feetRangeData[1]"
              :step="STEP" class="slider-purple" @update="updateForm('feetRange', $event)" :disabled="disableAction" />

          </div>
        </div>
      </div>
    </template>
  </ModalLayout>
  <div class="button-container">
    <Button class="button-container__button" :text="t('actions.back')" bgColor="var(--primary-color)"
      @click="this.$emit('click:prev', $event)" txtColor="#d7c8f1" />
    <Button class="button-container__button" :text="t('actions.next')" @click="this.$emit('click:next', $event)"
      txtColor="#f4edff" />

  </div>
</template>

<script scoped>
import Slider from "@vueform/slider";
import ModalLayout from "@/components/Shared/ModalLayout.vue";
import "@vueform/slider/themes/default.css";
import Button from "@/components/Shared/Button.vue";
import MultiButton from "@/components/Shared/MultiButton.vue";
import { formatPrice } from "@/utils";
import { bedroomsFilterMap } from "@/constants";
import useI18n from '@/composables/useI18n';

const RANGE_MIN_GAP = 150;
const STEP = 1;

export default {
  components: {
    ModalLayout,
    Slider,
    MultiButton,
    Button,
  },

  props: {
    /**
     * Form data object
     */
    form: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Available units objects
     */
    availableUnits: {
      type: Array,
      default: () => [],
    },
  },

  emits: ["click:prev", "click:next", "update:filter"],

  setup() {
    const { t } = useI18n();

    return {
      t,
      STEP,
      formatPrice,
    };
  },

  computed: {
    bedrooms() {
      return this?.form?.bedrooms || [];
    },

    availableBeds() {
      const sortedBeds = this.availableUnits.map((floorplan) => floorplan.beds).sort();
      const uniqueBeds = Array.from(new Set(sortedBeds));
      const mappedBeds = uniqueBeds.map((bed) => ({
        key: bed,
        value: bedroomsFilterMap[bed] || String(bed),
      }));
      return mappedBeds;
    },

    availableUnitsCount() {
      return this.availableUnits.length;
    },

    disableAction() {
      return !this.bedrooms.length;
    },


    feetRangeData() {
      return this.getRangeByProp(this.unitsFilteredForRange, "sqft");
    },

    priceRangeData() {
      return this.getRangeByProp(this.unitsFilteredForRange, "price", (price) =>
        parseFloat(price)
      );
    },

    unitsFilteredForRange() {
      return this.unitsFilteredByBeds.length
        ? this.unitsFilteredByBeds
        : this.availableUnits;
    },

    unitsFilteredByBeds() {
      return this.availableUnits.filter((floorplan) =>
        this.bedrooms.includes(floorplan.beds)
      );
    },
  },

  watch: {
    priceRangeData(value) {
      if (this.form?.budgetRange) {
        const adjustedRangeValue = this.adjustRangeValue(
          this.form.budgetRange,
          value
        );

        if (
          adjustedRangeValue[0] !== this.form.budgetRange[0] ||
          adjustedRangeValue[1] !== this.form.budgetRange[1]
        ) {
          this.updateForm("budgetRange", adjustedRangeValue);
        }
      }
    },

    feetRangeData(value) {
      if (this.form?.feetRange) {
        const adjustedRangeValue = this.adjustRangeValue(
          this.form.feetRange,
          value
        );
        if (
          adjustedRangeValue[0] !== this.form.feetRange[0] ||
          adjustedRangeValue[1] !== this.form.feetRange[1]
        ) {
          this.updateForm("feetRange", adjustedRangeValue);
        }
      }
    },
  },

  methods: {
    /**
     * @param {array} units units array based on which range is calculated
     * @param {string} fieldName name of the field range is calculated for
     * @param {function} format formatter for target value (optional)
     * @returns {Array} prop range in [min, max] format
     */
    getRangeByProp(units = [], fieldName, format = (value) => value) {
      if (!fieldName || !units.length) {
        return [0, RANGE_MIN_GAP];
      }

      let min = format(units[0][fieldName]),
        max = format(units[0][fieldName]);

      if (units.length === 1) {
        return [min, min + RANGE_MIN_GAP];
      }

      units.forEach((unit) => {
        const formattedValue = format(unit[fieldName]);

        if (min > formattedValue) {
          min = formattedValue;
        }

        if (max < formattedValue) {
          max = formattedValue;
        }
      });

      return [min, max];
    },

    /**
     * Adjust previosly set range value according to the new range
     * @param {array} prevRange previosly set range value in [min, max] format
     * @param {array} newRange new range in [min, max] format
     * @returns {Array} adjusted range value in [min, max] format
     */
    adjustRangeValue(prevRange = [], newRange = []) {
      if (newRange.length < 2 || prevRange.length < 2) {
        return prevRange;
      }

      if (prevRange[0] >= newRange[1] || prevRange[1] <= newRange[0]) {
        return newRange;
      }

      const min = prevRange[0] < newRange[0] ? newRange[0] : prevRange[0];
      const max = prevRange[1] > newRange[1] ? newRange[1] : prevRange[1];

      return [min, max];
    },

    updateForm(key, value) {
      this.$emit("update:filter", key, value);
    },
  },
};
</script>

<style scoped>
.price-label {
  color: var(--tertiary-color);
  margin-inline: 6px;
  font-size: 15px;
}

.wrapper-price-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  padding-inline: 26px;
}

.bed-container {
  margin-bottom: 40px;
}

.input-container {
  display: flex;
  flex-direction: column;
}

.space {
  margin-bottom: 20px;
  margin-left: 10px;
}

.button-container {
  height: 10%;
  width: 100%;
  display: flex;
  background-color: var(--primary-color);
  align-items: center;
  position: absolute;
  padding: 8px;
  bottom: 0;
  border-radius: 0px 0px 32px 32px;
  border-top: 1px solid var(--secondary-color);
  justify-content: space-evenly;
}

.button-container__button {
  margin-right: 5%;
  text-transform: lowercase;
}

.button-container__button:last-child {
  margin-right: 0;
}

.slider {
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px;
  background-color: var(--primary-color);
  color: white;
  border: 1px solid var(--secondary-color) !important;
  cursor: pointer;
  font-size: 12px;
}

.field {
  padding-inline: 36px;
  padding-block: 20px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.slider-purple {
  --slider-connect-bg: var(--secondary-color);
  --slider-tooltip-bg: var(--secondary-color);
  --slider-handle-ring-color: #3b82f630;
}
</style>
