<template>
  <div class="modals-container">
    <!-- TourOptions -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 0">
        <TourOptions @selfGuidedSelected="goToSelfGuidedTour" @inPersonSelected="goToInPersonTour" />
      </div>
    </XyzTransition>

    <!-- Self-Guided Tour -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 1">
        <SelfGuided @closeTourSelected="closeModal" />
      </div>
    </XyzTransition>

    <!-- Date -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 2">
        <AvailabilityDates @pick-date-clicked="goToCalendar" @dateSelected="setDate" :value="date" />
        <container-buttons :textLeft="t('actions.cancel')" :onClickLeft="closeModal"
          :onClickRight="() => date && setStep(4)" :textRight="t('actions.next')" />
      </div>
    </XyzTransition>

    <!-- Calendar -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 3">
        <Calendar @dateSelected="setDate" :value="date" />
        <container-buttons :textLeft="t('actions.cancel')" :onClickLeft="closeModal"
          :onClickRight="() => date && setStep(4)" :textRight="t('actions.next')" />
      </div>
    </XyzTransition>

    <!-- Time -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 4">
        <Time @timeSelected="setTime" :date="date" :time="time" />
        <container-buttons :textLeft="t('actions.back')" :onClickLeft="() => setStep(previousStep == 3 ? 3 : 2)"
          :onClickRight="() => time && setStep(5)" :textRight="t('actions.next')" />
      </div>
    </XyzTransition>

    <!-- Form -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 5">
        <ContactForm :onClickPrev="() => (currentStep = 4)" :onClickReview="() => (currentStep = 6)"
          :form="form" @update-form="updateForm" />
      </div>
    </XyzTransition>

    <!--Form Step 1-->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 6">
        <ScheduleDate @dateSelected="setMoveInDate" :value="moveInDate" title="When do you want to move in?" />
        <container-buttons :textLeft="t('actions.back')" :onClickLeft="() => setStep(5)"
          :onClickRight="() => setStep(7)" :textRight="t('actions.next')" />
      </div>
    </XyzTransition>

    <!-- Form Step 2 -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 7">
        <BedroomPreference :availableUnits="allUnits" @click:prev="() => setStep(6)" @click:next="() => setStep(8)"
          :form="form" @update:filter="updateForm" />
      </div>
    </XyzTransition>

    <!-- Review -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 8">
        <Review :form="{ ...form, date, time, moveInDate }" :date="dateTime" :onClickPrev="() => setStep(7)"
          :onClickNext="() => setStep(9)" />
      </div>
    </XyzTransition>

    <!-- Feedback -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 9">
        <Confirm />
        <div class="subModal" v-if="subModal" ref="subModal">
          <add-to-calendar-button :name="`${t('video_window.schedule_tour')} - ${
              community.c_community_name
            }`"
            :description="`Meeting with: ${community.c_community_name}, ${community.c_email}, (${community.c_phone_number})`"
            :startDate="calendarDate.startDate" :startTime="calendarDate.startTime" :endTime="calendarDate.endTime"
            timeZone="US/Central" :location="`${community.c_address.trim()}, ${
              community.c_city
            } ${community.c_state}, ${community.c_postal_code}`" options="'Apple','Google','Outlook.com'"
            listStyle="modal" buttonStyle="round" trigger="click" buttonsList hideBackground lightMode="dark" size="5"
            styleDark="--btn-background: #3e2a62; --btn-border: #9787b5; --btn-background-hover: #3e2a62;" />
        </div>
        <container-buttons :textLeft="t('button_add_feedback')" :onClickLeft="() => setStep(10)"
          :onClickRight="showSubModal" :textRight="t('button_add_to_calendar')" />
      </div>
    </XyzTransition>

    <!-- rate -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 10">
        <Rate :onClickNext="() => setStep(11)" @rate-clicked="nohideButtons = true" />
        <container-buttons :textCenter="t('button_details')" :onClickCenter="() => setStep(11)" />
        <Confirm />
      </div>
    </XyzTransition>

    <!-- rate -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 11">
        <Feedback :onClickPrev="() => setStep(10)" :onClickNext="() => setStep(12)" />
      </div>
    </XyzTransition>

    <!-- Thanks -->
    <XyzTransition xyz="fade">
      <div v-if="currentStep == 12">
        <Finish />
        <container-buttons :textCenter="t('actions.done')" :onClickCenter="closeModal" />
      </div>
    </XyzTransition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import 'add-to-calendar-button'
import { atcb_action } from 'add-to-calendar-button'

import TourOptions from '@/components/ScheduleTour/Wizard/TourOptions.vue';
import UnitService from "@/services/unit.service";
import SelfGuided from '@/components/ScheduleTour/Wizard/SelfGuided.vue';
import AvailabilityDates from '@/components/ScheduleTour/Wizard/AvailabilityDates.vue';
import Calendar from '@/components/ScheduleTour/Wizard/Calendar.vue';
import Time from '@/components/ScheduleTour/Wizard/Time.vue'
import ContactForm from '@/components/ScheduleTour/Wizard/ContactForm.vue'
import ScheduleDate from '@/components/Shared/ScheduleDate.vue'
import BedroomPreference from "@/components/Floorplans/BedroomPreference.vue";
import Review from '@/components/ScheduleTour/Wizard/Review.vue'
import Confirm from '@/components/ScheduleTour/Wizard/Confirm.vue'
import Rate from '@/components/ScheduleTour/Wizard/Rate.vue'
import Feedback from '@/components/ScheduleTour/Wizard/Feedback.vue'
import Finish from '@/components/ScheduleTour/Wizard/Finish.vue'
import ContainerButtons from '@/components/Shared/ContainerButtons.vue'
import useI18n from '@/composables/useI18n';

import moment from 'moment'

const communitiesWithTourOptions = ["73b6c7c3-ece9-4760-8b32-01c2c16cac1c", "4066738c-1694-4aec-acf0-f296607e79d1"];

const initialFormValues = Object.freeze({
  name: '',
  lastname: '',
  email: '',
  phone: '',
  bedrooms: [],
  bathrooms: 1,
  notes: '',
});

export default {
  name: 'ScheduleTourIndex',

  components: {
    TourOptions,
    SelfGuided,
    AvailabilityDates,
    Calendar,
    Time,
    ContactForm,
    BedroomPreference,
    ScheduleDate,
    Review,
    Confirm,
    Rate,
    Feedback,
    Finish,
    ContainerButtons
},

setup(){
  const { t } = useI18n();
  return { t };
},

  data() {
    return {
      subModal: false,
      currentStep: 0,
      previousStep: 2, 
      nohideButtons: false,
      allUnits: null,
      form: initialFormValues,
      moveInDate: null,
      date: null,
      time: '',
    }
  },
  
 async mounted() {
    document.addEventListener('click', this.handleClickOutside);

    if (!communitiesWithTourOptions.includes(this.community.id_community)) {
      this.currentStep = 2
    }

    this.allUnits = await UnitService.getAvailabileUnits(true);
  },

  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  },

  computed: {
    ...mapGetters({
      community: 'main/getCommunity',
    }),
    scene() {
      return this.getScene
    },
    dateTime() {
      return `${this.date} ${this.time}`
    },
    calendarDate() {
      const eventTime = moment(this.time, 'h:mm A')
      const startTime = eventTime.format('HH:mm')
      const endTime = eventTime.add(15, 'minutes').format('HH:mm')

      return {
        startDate: moment(this.date, 'M/D/YYYY').format('YYYY-MM-DD'),
        startTime,
        endTime,
      }
    },
  },

  methods: {
    ...mapActions({
      setModal: 'main/setModal',
    }),
    showSubModal() {
      this.subModal = true
    },
    handleClickOutside(event) {
      if (this.currentStep !== 5) return
      if (!event || !this.subModal) return
      if (JSON.stringify(event.srcElement) === '{}') this.subModal = false
    },
    scheduleEvent() {
      atcb_action(
        {
          options: ['Google', 'Outlook.com'],
          hideBackground: true,
          listStyle: 'modal',
          buttonStyle: 'round',
          buttonsList: true,
        },
        document.getElementById('btn-schedule-event')
      )
    },
    setStep(step) {
      this.currentStep = step;
    },
    updateForm(key, value) {
      this.form = { ...this.form, [key]: value };
    },
    async closeModal() {
      this.setModal(null)
    },
    setDate(date) {
      this.date = date.userText
    },
    setTime(time) {
      this.time = time
    },
     setMoveInDate(date) {
      this.moveInDate = date.userText;
    },
    goToSelfGuidedTour() {
      this.setStep(1);
    },
    goToInPersonTour() {
      this.setStep(2);
    },
    goToCalendar() {
      this.setStep(3);
      this.previousStep = 3;
    },
  },
}
</script>
<style scoped>
  .modals-container {
    text-align: left;
    color: #ffffff;
    height: 100%;
    position: relative;
    background-color: var(--shadow-purple-color);
    padding-top: 14%;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 25%);
    border-radius: 28px;
  }

.button-container {
  width: 100%;
  height: 10%;
  display: flex;
  background-color: var(--primary-color);
}
.left {
  display: flex;
}
.right {
  display: flex;
}

.button-container > div {
  width: 46%;
}

.begin-btn {
  font-size: 15px;
  align-self: center;
}

.subModal {
  position: fixed;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  width: 220px;
  border-radius: 8px;
}
</style>