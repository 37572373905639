<template>
  <div class="tabs">
    <div class="tabs__group">
      <FilterTab class="tab--all" :active="isSelected(null)" @change="$emit('change', null)">all</FilterTab>
    </div>

    <div class="tabs__group">
      <FilterTab v-for="option in options" :key="option.key" :active="isSelected(option.key)"
        @change="$emit('change', option.key)">{{ option.label }}</FilterTab>
    </div>
  </div>
</template>

<script setup>
import FilterTab from '@/components/Floorplans/FilterTab.vue';

const props = defineProps({
  /**
   * Filter options as [{key, label}]
   */
  options: {
    type: Array,
    default: () => [],
  },
  /**
   * Selected filter value
   */
  selected: {
    type: [String, Number],
    default: undefined,
  },
});
defineEmits([
  /**
   *  Emitted on filter value change
   */
  "change",
]);

function isSelected(key) {
  return props.selected === key;
}
</script>

<style scoped>
.tabs {
  display: flex;
  overflow: auto;
}

.tabs__group {
  display: flex;
}

.tab--all:not(.tab--active) {
  color: #8662BF;
}

.tabs__group:not(:last-child) {
  margin-right: 10px;
}
</style>
