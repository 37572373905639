<template>
  <ModalLayout>
    <template #title>
      {{ t('floorplans.availability.title') }}
    </template>

    <template #subtitle>
      {{ t('floorplans.availability.description') }}
    </template>

    <template #main>
      <div class="content">
        <div class="floorplans-filter">
          <span class="floorplans-filter__results">{{t('results')}} ({{ filteredFloorplans.length }})</span>
          <ListFilter :selected="filters.floorplanList.bedrooms" @change="onFilterChange" :options="bedroomsFilterOptions" />
        </div>
        <ul class="floorplans">
          <template v-for="floorplan in allFloorplans" :key="floorplan.id">
            <li v-show="inFilteredFloorplans(floorplan)" @click="$emit('click:floorplan', floorplan)"
              class="floorplans-item">
              <span class="floorplans-item__image" :ref="(el) => { loadBackgroundImage(el, floorplan.image) }" />
              <span class="floorplans-item__info">
                <h3 class="floorplans-item__heading">{{ floorplan.name }}</h3>
                <span class="floorplans-item__details">
                  {{ getFloorplanDetailsLabel(floorplan) }}
                </span>
              </span>
              <span v-if="floorplan.bestMatch" class="floorplans-item__match">Best Match</span>
              <i class="floorplans-item__arrow" />
            </li>
          </template>
        </ul>
      </div>
    </template>
  </ModalLayout>
</template>

<script>
import { inject } from 'vue'
import ModalLayout from "@/components/Shared/ModalLayout.vue";
import useI18n from '@/composables/useI18n';
import ListFilter from '@/components/Floorplans/ListFilter.vue';
import { filterFloorplansByBedrooms, getBedroomLabel } from '@/utils/floorplans';
import { loadBackgroundImage } from '@/utils/image';
import { isValidNumber } from '@/utils/number';

export default {
  name: 'FloorplansList',

  components: {
    ModalLayout,
    ListFilter,
  },

  props: {
    /**
     * Floorplans list
     */
    floorplans: {
      type: Array,
      default: () => [],
    },
    /**
     * Array of bedrooms selected on previously 
     */
    preselectedBedrooms: {
      type: Array,
      default: () => [],
    },
    /**
     * Floorplans filters object
     */
    filters: {
      type: Object,
      default: () => ({}),
    }
  },

  emits: [
    /**
     * Emitted on floorplan item click
     */
    'click:floorplan'
  ],

  setup() {
    const { t } = useI18n();
    const updateFilters = inject('updateFilters');

    return { t, updateFilters };
  },

  computed: {
    filteredFloorplans() {
      if(!isValidNumber(this.filters.floorplanList.bedrooms)){
        return this.allFloorplans;
      }

      return filterFloorplansByBedrooms(this.allFloorplans, [this.filters.floorplanList.bedrooms] );
    },

    allFloorplans() {
      const compareFloorplansBybedrooms = (a, b) => {
        return a.beds - b.beds;
      }

      const sortBestMatch = (a, b) => {
        if (a.bestMatch && !b.bestMatch) {
          return -1;
        }
        if (!a.bestMatch && b.bestMatch) {
          return 1;
        }
        return 0;
      }
      return Array.from(this.floorplans).sort(compareFloorplansBybedrooms).sort(sortBestMatch);
    },
    bedroomsFilterOptions() {
      return Array.from(this.preselectedBedrooms).sort().map(bed => ({ key: bed, label: getBedroomLabel(bed) }))
    },
  },

  methods: {
    loadBackgroundImage,

    onFilterChange(key = null){
      this.updateFilters('floorplanList.bedrooms', key);
    },

    getFloorplanDetailsLabel(floorplan = {}) {
      return `${floorplan.beds} Bed | ${floorplan.half_baths ? floorplan.baths + ".5" : floorplan.baths} Bath`;
    },

    inFilteredFloorplans(targetFloorplan = {}) {
      if (!targetFloorplan.id) {
        return false;
      }

      return this.filteredFloorplans.findIndex(floorplan => floorplan.id === targetFloorplan.id) > -1;
    },
  }
}
</script>

<style scoped>
.floorplans-item__arrow {
  border: solid var(--white);
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  color: var(--white);
  transform: rotate(-45deg);
  margin-right: 20px;
}

.floorplans-filter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

.floorplans-filter__results {
  color: #C4C4C4;
  margin-right: 12px;
}

.floorplans-item {
  height: 72px;
  border: 1px solid var(--secondary-color);
  border-radius: 8px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-color);
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
}

.floorplans-item__image {
  height: 100%;
  width: 72px;
}

.floorplans-item__info {
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-left: 24px;
  padding-right: 24px;
}

.floorplans-item__details {
  font-size: 8px;
}

.floorplans-item__heading {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 0;
}

.floorplans {
  overflow: auto;
  max-height: 43vh;
}

.floorplans-item__match {
  color: var(--white);
  padding: 4px 8px;
  font-size: 10px;
  font-weight: 700;
  margin-right: 10px;
  border-radius: 4px;
}
</style>