<template>
  <ModalLayout>
    <template v-slot:title>
      <span class="content-title">{{
        t('schedule_tour.review_title_feedback')
      }}</span>
    </template>
    <template v-slot:main>
      <div class="content space">
        <label class="subtitle">{{
          t('schedule_tour.review_subtitle_feedback')
        }}</label>
        <text-area-input
          id="notes"
          :value="this.feedback"
          @update:value="updateFeedback($event)"
          placeholder="your comments here..."
          :messageError="t('schedule_tour.required_field')"
        />
      </div>
    </template>
  </ModalLayout>
  <container-buttons
    :textLeft="t('actions.back')"
    :onClickLeft="() => this.onClickPrev()"
    :onClickRight="() => this.submit()"
    :textRight="t('actions.submit')"
  />
</template>

<script scoped>
import { mapGetters } from 'vuex'
import TextAreaInput from '@/components/Shared/TextAreaInput.vue'
import ModalLayout from '@/components/Shared/ModalLayout.vue'
import ContainerButtons from '@/components/Shared/ContainerButtons.vue'
import UserService from '@/services/user.service'
import useI18n from '@/composables/useI18n';

export default {
  name: 'ScheduleTourFeedback',

  components: {
    TextAreaInput,
    ModalLayout,
    ContainerButtons,
  },

  setup(){
    const { t } = useI18n();
    return { t };
  },

  computed: {
    ...mapGetters({
      getConversationId: "user/getConversationId",
    }),
    conversationId() {
      return this.getConversationId;
    },
  },
  props: {
    onClickPrev: Function,
    onClickNext: Function,
  },
  data() {
    return {
      errors: [],
      feedback: '',
    }
  },
  methods: {
    updateFeedback(value) {
      this.feedback = value
    },
    async submitClicked() {
      console.log('submitClicked #1')

      if (this.feedback.trim() != '') {
        const payload = {
          conversation_id: this.conversationId,
          notes: this.feedback,
        }

        console.log('payload', payload)

        UserService.saveFeedbackComment(payload)

        this.onClickNext()
      }

    },
    validateFields() {
      if (!this.feedback) this.errors.push('feedback')
    },
    submit() {
      this.errors = []
      this.validateFields()
      this.submitClicked()
    },
  },
}
</script>

<style scoped>
hr {
  border-top: 1px solid var(--secondary-color);
}

.content {
  display: flex;
  flex-direction: column;
  height: 46vh;
}

.input-container {
  display: flex;
  flex-direction: column;
}

input {
  padding: 10px;
  border: 1px solid #ccc;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
}

.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: var(--tertiary-color);
  margin-bottom: 36px;
}

.space {
  margin-bottom: 36px;
}

.required {
  width: 266px;
  height: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: var(--white);
}

.left {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.right {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.button-container>div {
  width: 46%;
}

.input-feedback {
  width: 30px;
  border-radius: 1px solid;
}

.content-title {
  font-size: 25px;
}

@media only screen and (max-height: 700px) {
  .content {
    height: 32em;
  }
}
</style>
