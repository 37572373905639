<template>
  <div class="wrapper-digital-human">
    <VideoWindow id="videoWindow" />
    <Menu id="menu" />
    <Chat id="chat" @microphoneState="handleMicState" />
    <XyzTransition appear xyz="fade duration-3">
      <Modal v-show="modal != null" id="modal" />
    </XyzTransition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VideoWindow from "@/components/VideoWindow/VideoWindowIndex.vue";
import Menu from "@/components/Menu/MenuIndex.vue";
import Chat from "@/components/Chat/ChatIndex.vue";
import Modal from "@/components/Shared/Modal.vue";
import SDKService from "@/services/sdk.service";
import { LocalesLabels } from '@/constants/locales';
import useI18n from '@/composables/useI18n';

export default {
  name: "DigitalHuman",

  components: {
    VideoWindow,
    Menu,
    Chat,
    Modal,
  },
  setup(){
    const {locale} = useI18n();
    return {locale};
  },

  data() {
    return {
      scene: null,
      microphoneState: true
    };
  },

  watch: {
    modal(newModal, oldModal) {
      if (newModal) {
        this.scene.stopRecognize();
      } else if (oldModal && this.microphoneState) {
        this.scene.startRecognize();
      }
    }
  },

  computed: {
    ...mapGetters({
      getRemoteVideo: "sdk/getRemoteVideo",
      getModal: "main/getModal",
      getCurrentPersona: "main/getCurrentPersona",
      getCommunity: "main/getCommunity",
      getConversationId: "user/getConversationId",
      getGuestId: "user/getGuestId",
    }),

    remoteVideo() {
      return this.getRemoteVideo;
    },

    modal() {
      return this.getModal;
    },

    language() {
      return LocalesLabels[this.locale];
    },

    currentPersona() {
      return this.getCurrentPersona;
    },

    community() {
      return this.getCommunity;
    },

    conversationId(){
      return this.getConversationId;
    },

    guestId(){
      return this.getGuestId;
    }
  },

  async created() {
    this.setLoaderIsShowing("Connecting with the agent");
  },

  async mounted() {
    await this.connect();

    // // Active
    // window.addEventListener('focus', this.appIsActive);

    // // Inactive
    // window.addEventListener('blur', this.appIsNotActive);

    const that = this;

    document.addEventListener("visibilitychange", function() {
      
      // console.log("=================>>", document.hidden, document.visibilityState);
      if(document.hidden){
        that.appIsNotActive();
      }else{
        that.appIsActive();
      }
    }, false);
  },

  methods: {
    ...mapActions({
      setConversation: "conversation/setConversation",
      setScene: "sdk/setScene",
      setPersona: "sdk/setPersona",
      setLoaderIsShowing: "main/setLoaderIsShowing",
      saveConversation: "user/saveConversation",
    }),

    async connect() {
      // Creates and connects to the scene
      this.scene = await SDKService.createScene(this.remoteVideo);

      // Connects to the scene
      await SDKService.connectScene(
        this.scene,
        this.onConnectionSuccess,
        this.onConnectionError,
        this.language,
        this.currentPersona
      );

      this.setScene(this.scene);

      this.scene.startRecognize();

      this.scene.microphone = true;

      this.scene.onDisconnected = this.sessionExpired;
      this.scene.onRecognizeResults = this.onRecognizeResults;
    },

    // Callback called after a successfull connection
    async onConnectionSuccess(sessionId) {
      console.log("success! session id: ", sessionId);

      // start the video playing
      this.scene
        .startVideo()
        .then((videoState) => this.onVideoStarted(videoState))
        .catch((error) => console.log("could not start video: ", error));
    },

    // Callback called after the video has started
    async onVideoStarted(videoState) {
      console.log("started video with state: ", videoState);

      // Creates the agent persona
      let persona = await SDKService.createPersona(this.scene);

      // Add listener on conversation result
      persona.onConversationResultEvent.addListener(this.onConversationResult);

      // Initial converstion string
      const text = `Community: ${this.community.id_community}, Digital_human_name: ${this.currentPersona}, Language: ${this.language}`;

      const that = this;

      persona
        .conversationSend(text)
        .then(function () {
          // Hides the loader
          that.setLoaderIsShowing(false);
        })
        .catch((error) => console.log("Request Error", error));

      this.setPersona(persona);
    },

    onConversationResult(persona, result) {
      console.log("====== onConversationResult ======");

      console.log("=> result");
      console.log(result);

      this.saveConversationHistory(result.input.text)

      this.setConversation({
        input: result.input.text,
        output: result.output.text,
        fulfillmentMessages:
          result.provider.meta.dialogflow.queryResult.fulfillmentMessages,
        intentName:
          result.provider.meta.dialogflow.queryResult.intent.displayName,
      });
    },

    async saveConversationHistory(request) {
      const payload = {
        community_id: this.community.id_community,
        conversation_id: this.conversationId,
        customer_id: this.community.customer_id,
        browser_platform: window.navigator.userAgent,
        digital_human_name: this.currentPersona,
        language: this.language,
        communication_method: "web",
        input_type: "typed",
        user: {
          guest_id: this.guestId,
          request: request,
          client_time: new Date().toLocaleTimeString(),
          location: {
            latitude: "9.9044373",
            longitude: "-84.0842156",
          },
        },
      };

      await this.saveConversation(payload);
    },

    // Callback called after a error connection
    async onConnectionError(error) {
      console.log("connection failed:", error);

      switch (error.name) {
        case "noUserMedia":
          console.log("user declined device access");
          break;
        case "noScene":
        case "serverConnectionFailed":
          console.log("server connection failed");
          break;
        default:
          console.log("unhandled error: ", error);
      }
    },

    async sessionExpired(scene, sessionId, reason) {
      console.log("== sessionExpired ==");
      console.log(scene);
      console.log(sessionId);
      console.log(reason);

      let loaderMessage = "";

      switch (reason) {
        case "sessionTimeout":
          loaderMessage =
            "Session Expired!. Please, refresh the page to start a new session.";
          break;
        default:
          loaderMessage = "Session Expired!";
      }

      this.setLoaderIsShowing(loaderMessage);
    },

    async onRecognizeResults(scene, status, errorMessage, results) {
      console.log("== onRecognizeResults ==");
      console.log(scene);
      console.log(status);
      console.log(errorMessage);
      console.log(results);
    },

    handleMicState(microphoneState) {
      this.microphoneState = microphoneState;
      console.log("Microphone state", this.microphoneState);
    } 
  }
};
</script>
<style scoped>
#videoWindow {
  height: 50vh;
}
#menu {
  /* background: lightblue; */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  z-index: 90;
}
#chat {
  height: 50vh;
}

#modal {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
}
.wrapper-digital-human {
  position: relative;
}
</style>