<template>
  <div class="modals-container">
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 0">
        <ScheduleDate @dateSelected="setMoveInDate" :value="moveInDate" title="When do you want to move in?" />
        <ContainerButtons :textLeft="t('actions.cancel')" :onClickLeft="closeModal" :onClickRight="() => setStep(1)"
          :textRight="t('actions.next')" />
      </div>
    </XyzTransition>

    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 1">
        <FloorplansFormOne :onClickPrev="() => setStep(0)" :onClickNext="() => setStep(2)"
          :onClickSkip="() => setStep(2)" :form="form" @update-form="updateForm" />
      </div>
    </XyzTransition>

    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 2">
        <BedroomPreference :form="formWithMoveIn" :availableUnits="availableUnits" @click:prev="() => setStep(1)"
          @click:next="() => {
        updateGuest();
        setStep(3);
      }
        " @update:filter="updateForm" />
      </div>
    </XyzTransition>

    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 3">
        <FloorplansList v-if="filteredFloorplans.length" :floorplans="filteredFloorplans"
          :preselectedBedrooms="preselectedBedrooms" :filters="filters" @click:floorplan="onFloorplanClick" />
        <div v-else>
          <div class="placeholder-container">
            <h4>No units available</h4>
            <br />
            <h5>Lets adjust your preferences and give it another try</h5>
            <br />
            <!-- <ButtonIcon symbol="rotate-ccw" @click="setStep(2)" text="Edit" class="begin-btn" type="btn-white"></ButtonIcon> -->
            <button @click="setStep(2)" class="edit-button">
              <vue-feather type="rotate-ccw" class="icon"></vue-feather>
              <span>Edit</span>
            </button>
          </div>
        </div>
        <ContainerButtons v-if="filteredFloorplans.length !== 0" :textLeft="t('actions.edit_search')"
          :onClickLeft="() => setStep(2)" :textRight="t('actions.clear')" :onClickRight="resetSelectedBedrooms" />
      </div>
    </XyzTransition>

    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 4 && selectedFloorplan != null">
        <FloorplansDetail @onScheduleClick="onScheduleClick" :floorplan="selectedFloorplan" />
        <ContainerButtons :textLeft="t('actions.back')" :onClickLeft="() => setStep(3)" />
      </div>
    </XyzTransition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { provide, reactive } from 'vue';
import { v4 as uuidv4 } from "uuid";
import UnitService from "@/services/unit.service";
import FloorplansList from "@/components/Floorplans/FloorplansList.vue";
import FloorplansDetail from "@/components/Floorplans/FloorplansDetail.vue";
import FloorplansFormOne from "@/components/Floorplans/FloorplansFormOne.vue";
import BedroomPreference from "@/components/Floorplans/BedroomPreference.vue";
import ScheduleDate from "@/components/Shared/ScheduleDate.vue";
import ContainerButtons from "@/components/Shared/ContainerButtons.vue";
import ConversionService from "@/services/conversion.service";
import { numberToBedroomCount } from "@/utils";
import useI18n from '@/composables/useI18n';
import { filterFloorplansByBedrooms } from '@/utils/floorplans';
import { setProperty } from "@/utils/object";


const initialFormValues = Object.freeze({
  name: "",
  lastname: "",
  email: "",
  phone: "",
  bedrooms: [],
});

export default {
  components: {
    FloorplansList,
    FloorplansDetail,
    ScheduleDate,
    FloorplansFormOne,
    BedroomPreference,
    ContainerButtons,
  },

  setup() {
    const { t } = useI18n();
    const filters = reactive({
      floorplanList: {
        bedrooms: null,
      },
    });

    function updateFilters(key, value) {
      setProperty(filters, key, value)
    }

    provide('updateFilters', updateFilters);

    return { t, filters };
  },

  data() {
    return {
      currentStep: 0,
      selectedFloorplan: null,
      availableUnits: null,
      date: null,
      form: initialFormValues,
      moveInDate: null,
    };
  },

  computed: {
    ...mapGetters({
      persona: "sdk/getPersona",
      communityId: "main/communityId",
      customerId: "main/customerId",
    }),

    formWithMoveIn() {
      return { ...this.form, moveInDate: this.moveInDate };
    },

    allBedrooms() {
      const sortedBeds = this.availableUnits.map((floorplan) => floorplan.beds).sort();
      return Array.from(new Set(sortedBeds));
    },

    filteredFloorplans() {
      if (!this.form.bedrooms.length) {
        return this.availableUnits;
      }

      return filterFloorplansByBedrooms(this.availableUnits, this.form.bedrooms).map(i => {
        const budgetRange = this.form.budgetRange;
        const feetRange = this.form.feetRange;
        if (budgetRange && feetRange &&
          budgetRange[0] <= parseInt(i.price) && parseInt(i.price) <= budgetRange[1] && feetRange[0] <= i.sqft && i.sqft <= feetRange[1]
        ) {
          return { ...i, bestMatch: true };
        }
        return i;
      });
    },

    preselectedBedrooms() {
      if (!this.form.bedrooms.length) {
        return this.allBedrooms;
      }

      return this.form.bedrooms;
    },
  },

  async mounted() {
    this.availableUnits = await UnitService.getAvailabileUnits();
  },

  methods: {
    ...mapActions({
      setModal: "main/setModal",
    }),
    updateForm(key, value) {
      this.form = { ...this.form, [key]: value };
    },
    setStep(step) {
      this.currentStep = step;
    },
    setMoveInDate(date) {
      this.moveInDate = date.userText;
    },
    async closeModal() {
      this.setModal(null);
    },

    async onScheduleClick() {
      console.log("== Schedule ==");
      if (this.persona) {
        this.persona
          .conversationSend("Schedule A Tour")
          .catch((e) => console.error(e));
      }
    },

    async onFloorplanClick(floorplan) {
      console.log("onFloorplanClick x", floorplan);

      this.selectedFloorplan = floorplan;
      this.setStep(4);
    },

    resetSelectedBedrooms() {
      this.updateForm('bedrooms', initialFormValues.bedrooms);
    },

    getGuestPayload() {
      return {
        id_guest: uuidv4(), // missing value
        first_name: this.form.name,
        last_name: this.form.lastname,
        phone_number: this.form.phone,
        email: this.form.email,
        notes: this.form.notes,
      };
    },

    getBedroomsPayload(bedrooms = []) {
      return bedrooms.length
        ? Array.from(bedrooms).map((v) => numberToBedroomCount(v))
        : [numberToBedroomCount(1)];
    },

    getGuestCardPayload() {
      return {
        prospect: {
          first_name: this.form.name,
          last_name: this.form.lastname,
          phone: this.form.phone,
          email: this.form.email,
        },
        preferred: {
          move_in_start_date: this.form.moveInDate,
          no_of_bedrooms: this.getBedroomsPayload(this.form.bedrooms),
          // uses correct value here
          amount: this.form.budgetRange,
        },
        comment: this.form.notes,
        tour_included: false,
        source: "dh",
        community_id: this.communityId,
        customer: this.customerId,
      };
    },

    async updateGuest() {
      const payload = {
        guest: this.getGuestPayload(),
        guest_card: this.getGuestCardPayload(),
      };
      ConversionService.updateGuest(payload);
    },
  },
};
</script>
<style scoped>
.modals-container {
  text-align: left;
  color: var(--white);
  height: 100%;
  position: relative;
  background-color: var(--shadow-purple-color);
  padding-top: 14%;
  box-shadow: 0px 0px 16px rgb(0 0 0 / 25%);
  border-radius: 28px;
}

.button-container {
  width: 100%;
  height: 10%;
  display: flex;
  background-color: var(--primary-color);
}

.left {
  display: flex;
}

.right {
  display: flex;
}

.button-container>div {
  width: 46%;
}

.begin-btn {
  font-size: 15px;
  align-self: center;
}

.placeholder-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 5rem;
  text-align: center;
}

.edit-button {
  background-color: #eae2f8;
  /* light purple background */
  color: #7b4c9a;
  /* purple text color */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
  cursor: pointer;
}

.icon {
  margin-right: 10px;
  stroke-width: 2;
}

.edit-button:hover {
  background-color: #d3c0f0;
  /* darker shade for hover effect */
}
</style>
