<template>
    <ModalLayout>
        <template #top-left>
            <img v-if="community.community_logo_url" class="logo" :src="community.community_logo_url" :title="community.c_community_name" />
            <div v-else class="title">{{ community.c_community_name }}</div>
        </template>
        <template #main>
            <div class="content-container"
                :class="isMapView ? 'content-container--view-map' : 'content-container--view-list'">
                <div class="neighborhood__category-filter">
                    <SelectInput v-model="category" :options="filterOptions" />
                </div>
                <MapView v-show="isMapView" :is-map-view="isMapView" :category="category" :categories="filterOptions"
                    v-model:locations="locations" />
                <ListView v-show="!isMapView" :locations="locations" />
            </div>
        </template>
        <template #bottom>
            <Button :text="buttonText" bg-color="transparent" txt-color="#D7C8F1" @on-click="isMapView=!isMapView" />
        </template>
    </ModalLayout>
</template>

<script>
import ModalLayout from '@/components/Shared/ModalLayout.vue';
import SelectInput from '@/components/Neighborhood/SelectInput.vue';
import MapView from '@/components/Neighborhood/MapView.vue';
import ListView from '@/components/Neighborhood/ListView.vue';
import Button from '@/components/Shared/Button.vue';
import useI18n from '@/composables/useI18n';
import { mapGetters } from 'vuex';
import { mapFilters } from '@/constants/neighborhood_map';

export default {
    name: 'NeighborhoodIndex',

    components: {
        ModalLayout,
        SelectInput,
        MapView,
        ListView,
        Button,
    },

    setup(){
        const { t} = useI18n();
        return { t };
    },

    data() {
        return {
            isMapView: true,
            category: 'shopping_mall',
            locations: [],
        };
    },

    computed: {
        ...mapGetters({
            community: 'main/getCommunity',
        }),

        buttonText() {
            return this.isMapView ? this.t('neighborhood.switch_to.list') : this.t('neighborhood.switch_to.map');
        },

        filterOptions() {
            return mapFilters.map(item => ({
                ...item,
                key: item.id,
                value: item.title,
            })) ?? [];
        },
    },
}
</script>

<style scoped>
.wrapper {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100%;
}
.wrapper :deep(.top) {
    height: 92px;
    margin: 0;
    padding: 24px;
    border-radius: 30px 30px 0 0;
    background: #150E20;
}
.wrapper :deep(.main) {
    position: relative;
    padding: 0;
    height: calc(100% - 144px);
}
.wrapper :deep(.bottom) {
    display: flex;
    align-items: center;
    height: 52px;
    border-radius: 0 0 30px 30px;
    background: #150E20;
}

.title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.logo {
    max-height: 100%;
}

.content-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.neighborhood__category-filter {
    width: fit-content;
}

.content-container--view-map .neighborhood__category-filter {
    position: absolute;
    top: 12px;
    left: 24px;
}

.content-container--view-list {
    background: #32224E;
    overflow-y: scroll;
}

.content-container--view-list .neighborhood__category-filter {
    margin: 12px 24px 16px;
}
</style>
