<template>
    <div class="poi-list">
        <div class="poi-list__header">
            <div class="poi-list__results">
                {{ `${sortedItems.length} results` }}
            </div>
            <SelectInput v-model="sortBy" :options="sortOptions" :placeholder="t('neighborhood.sort_by')" transparent />
        </div>
        <div v-for="location in sortedItems" :key="location.place_id" class="poi-item">
            <div class="poi-item__name">
                {{ location.name }}
            </div>
            <div class="poi-item__rating-wrapper">
                <vue-feather type="star" size="16" stroke="#FDB022" fill="#FDB022" />
                <div class="poi-item__rating">
                    {{ location.rating.toFixed(1) }}
                </div>
                <div class="poi-item__reviews">
                    {{ `(${location.reviews} reviews)` }}
                </div>
            </div>
            <div class="poi-item__address">
                {{ location.vicinity }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, defineProps, computed } from "vue";
import SelectInput from '@/components/Neighborhood/SelectInput.vue';
import feather from "feather-icons";
import { sortOptions } from '@/constants/neighborhood_map';
import useSort from '@/components/Neighborhood/useSort';
import useI18n from '@/composables/useI18n';

const props = defineProps({
    locations: {
        type: Array,
        default: () => [],
    },
});

const locationsRef = computed(() => props.locations);
const { sortBy, sortedItems } = useSort(locationsRef);
const { t } = useI18n();

onMounted(() => {
    feather.replace();
})
</script>

<style scoped>
.poi-list {
    display: flex;
    flex-direction: column;
    padding: 0 16px 8px;
}
.poi-list__header {
    display: flex;
    justify-content: space-between;
}
.poi-list__header :deep(.dropdown__menu) {
    right: 0;
}
.poi-list__results {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #F4EDFF;
}
.poi-item {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 8px;
    padding: 16px;
    border-radius: 8px;
    background: #150E20;
}
.poi-item__name {
    text-align: start;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    letter-spacing: 0.25px;
    color: #FFFFFF;
}
.poi-item__rating-wrapper {
    display: flex;
    gap: 8px;
}
.poi-item__rating {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: #D0D5DD;
}
.poi-item__reviews {
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: #FFFFFF;
}

.poi-item__address {
    align-self: start;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #D7C8F1;
    letter-spacing: 0.25px;
}
</style>