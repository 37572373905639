import axios from "axios";
import store from "@/store";

class UnitService {
  async getAvailabileUnits(showAll) {
    const params = {showAll};

    return await axios
      .get(
        `${process.env.VUE_APP_SERVER_ENDPOINT}/unit/availability`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "X-Quext-Customer-Id": store.getters["main/customerId"],
            "X-Quext-Community-Id": store.getters["main/communityId"],
          },
          params,
        }
      )
      .then(
        (response) => {
          return response.data.data;
        },
        (error) => {
          console.error("== getAvailabileUnits error ==", error);
        }
      );
  }
}

export default new UnitService();
