export default {
  persona_select_button_privacy_policy: "Política de Privacidad",
  persona_select_button_browser_compatibility:
    "Compatibilidad del Navegador",
  persona_select_language: "Elige tu Idioma",
  persona_select_button_next: "Siguiente",
  persona_select_agent: "Seleccione su agente",
  persona_select_button_begin: "Comenzar",
  persona_select_button_back: "Atrás",
  persona_select_step: "DE",
};