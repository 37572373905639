<template>
  <ModalLayout>
    <template v-slot:title>
      {{ t("schedule_tour.review_title_review_details") }}
    </template>

    <template v-slot:main>
      <div class="content">
        <label class="subtitle">{{
          t("schedule_tour.review_title_appointment_summary")
          }}</label>
        <div class="detail-content">
          <div class="info-row">
            <label class="content-title">{{
              t("schedule_tour.review_label_date_time")
              }}</label>
            <label class="content-subtitle">{{
  formattedDate
              }}</label>
          </div>
          <div class="info-row">
            <label class="content-title">{{
              t("schedule_tour.review_label_location")
              }}</label>
            <label class="content-subtitle">{{
              communityName
              }}</label>
          </div>
          <div class="info-row">
            <label class="content-title" style="display: flex; align-items: flex-start">
              {{ t("schedule_tour.review_label_contact_details") }}
            </label>
            <div class="contact-info">
              <label class="content-subtitle">{{ fullName }}</label>
              <label class="content-subtitle">{{
                phone
                }}</label>
              <label class="content-subtitle">{{ form.email }}</label>
            </div>
          </div>
          <div class="info-row">
            <label class="content-title">{{
              t("schedule_tour.review_label_movein_date")
              }}</label>
            <label class="content-subtitle">{{ form.moveInDate }}</label>
          </div>
          <div class="info-row">
            <label class="content-title">{{
              t("schedule_tour.review_label_preferences")
              }}</label>
            <label class="content-subtitle">{{
              getFullBedroomsLabel(form.bedrooms)
            }}</label>
          </div>
          <div class="info-row">
            <label class="content-title">{{
              t("schedule_tour.review_label_price_range")
              }}</label>
            <label class="content-subtitle">{{ budget }}</label>
          </div>
        </div>
      </div>
    </template>
  </ModalLayout>

  <container-buttons :textLeft="t('actions.back')" :onClickLeft="onClickPrev" :onClickRight="submit"
    :textRight="t('actions.submit')" />
</template>

<script>
import { mapGetters } from "vuex";
import ModalLayout from "@/components/Shared/ModalLayout.vue";
import ConversionService from "@/services/conversion.service";
import ContainerButtons from "@/components/Shared/ContainerButtons.vue";
import { v4 as uuidv4 } from "uuid";
import { numberToBedroomCount } from "@/utils";
import moment from "moment";
import useI18n from '@/composables/useI18n';

export default {
  name: 'ScheduleTourReview',

  components: {
    ModalLayout,
    ContainerButtons,
  },

   setup(){
    const { t } = useI18n();
    return { t };
  },

  computed: {
    ...mapGetters({
      getCommunity: "main/getCommunity",
    }),
    community() {
      return this.getCommunity;
    },

    bedrooms(){
      const sortedBedrooms = [...this.form.bedrooms].sort();
      return sortedBedrooms.map(bedroomCount => this.t(`bedrooms.long.${bedroomCount}`)).join(', ');
    },

    budget(){
      if (this.form.budgetRange.length < 2){
        return '';
      }

      return `$[ ${ this.form.budgetRange.join(', ') } ]/MO`;
    },

    fullName(){
      return `${this.form.name} ${this.form.lastname}`;
    },

    phone(){
      return this.formatPhoneNumber(this.form.phone);
    },

    formattedDate(){
      return this.date.replace(" ", " @ ");
    },

    communityName(){
      return this.community.c_community_name;
    }
  },
  
  methods: {
    async submit() {
      await this.submitClicked();
    },
    async submitClicked() {
      let bedrooms = null;

      const date = moment(this.form.date, "M/D/YYYY").format("YYYY-MM-DD");
      const time = moment(this.form.time, "h:mm a").format("HH:mm:ss");

      if (this.form?.bedrooms?.length) {
        bedrooms = this.form.bedrooms.map(v => numberToBedroomCount(v));
      }

      // add form data to guest table
      const guestPayload = {
        id_guest: uuidv4(), // missing value
        first_name: this.form.name,
        last_name: this.form.lastname,
        phone_number: this.form.phone,
        email: this.form.email,
        notes: this.form.notes,
      };

      const guestCardPayload = {
        prospect: {
          first_name: this.form.name,
          last_name: this.form.lastname,
          phone: this.form.phone,
          email: this.form.email,
        },
        preferred: {
          move_in_start_date: this.form.moveInDate,
          no_of_bedrooms: bedrooms,
          amount: {
            min: parseInt(this.form.budgetRange[0]),
            max: parseInt(this.form.budgetRange[1]),
          },
        },
        comment: this.form.notes,
        tour_included: true,
        tour_data: {
          layout: bedrooms,
          description: "",
          start: date + " " + time,
          date: date,
          time: time,
        },
        source: "dh",
        community_id: this.community.id_community,
        customer: this.community.customer_id,
      };

      const payload = {
        guest: guestPayload,
        guest_card: guestCardPayload,
      };

      ConversionService.updateGuest(payload);

      this.onClickNext();
    },

    formatPhoneNumber(phoneNumber) {
      const regex = /^(\d{3})(\d{3})(\d{4})$/;
      const formattedNumber = phoneNumber.replace(regex, "+1 ($1) $2-$3");
      return formattedNumber;
    },
    getFullBedroomsLabel(bedrooms = []) {
      return bedrooms.map(key => this.t(`bedrooms.long.${key}`)).join(', ');
    },
  },
  props: {
    onClickNext: Function,
    onClickPrev: Function,
    form: {
      name: String,
      notes: String,
      lastname: String,
      email: String,
      phone: String,
      budgetRange: Array,
      moveInDate: String,
      date: String,
      time: String,
      errors: Array,
      bedrooms: Array,
      bathrooms: Number,
    },
    date: String,
    moveInDate: String,
  },
};
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.button-container > div {
  width: 46%;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
}

.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: var(--tertiary-color);
}

.my-hr {
  border: 0.5px solid var(--secondary-color);
  margin-top: 20px;
  margin-bottom: 20px;
}

.detail-content {
  margin-top: 20px;
}

.info-row {
  display: flex;
  margin-bottom: 14px;
}

.content-title {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: var(--tertiary-color);
  width: 40%;
}

.content-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  letter-spacing: 0.1px;
  color: var(--white);
}

.contact-info {
  flex-direction: column;
}
</style>
