export default {
  form_label_desired_number_bedrooms: "Dormitorios",
  form_label_max_budget: "Presupuesto Máximo",
  form_subtitle_fill_out_this_form: "Algunos datos para empezar",
  title_basic_info: "Información básica",
  label_phone: "Teléfono (Opcional)",
  required: "requerido*",
  required_field: "Este campo es requerido",
  invalid_email: "Este email es inválido",
  invalid_phone: "Teléfono inválido",
  placeholder_phone: "Introduce tu teléfono",
  review_title_review_details: "Revisar detalles",
  review_title_appointment_summary:
    "Envíe la información a continuación",
  review_label_date_time: "Hora programada",
  review_label_location: "Ubicación",
  review_label_movein_date: "Fecha de mudanza",
  review_label_contact_details: "Detalles de contacto",
  review_label_preferences: "Preferencia de dormitorio",
  review_label_price_range: "Presupuesto Máximo",
  review_title_feedback: "Comentarios",
  review_subtitle_feedback: "Añade tus comentarios abajo",
  review_title_appointment_confirmation: "¡Cita confirmada!",
  review_title_appointment_review:
    "¿Cómo calificaría su experiencia?",
  review_title_thankyou: "¡Gracias por tu Feedback!",
};