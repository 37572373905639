<template>
  <div>
    <span v-if="optionsEmpty">No bedrooms available</span>

    <ul v-else class="multi-button__buttons">
      <li
        class="multi-button__button"
        v-for="option in options"
        :key="option.key"
      >
        <button
          type="button"
          class="button"
          :class="isOptionSelected(option.key) ? 'button--active' : ''"
          @click="onSelect(option.key)"
        >
          <span class="button__inner">
            {{ option.value }}
          </span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script scoped>
export default {
  props: {
    /**
     * Array of items initially selected
     */
    selectedItems: {
      type: Array,
      default: () => [],
    },
    /**
     * Array of items as {key, value}
     */
    options: {
      type: Array,
      default: () => [],
    },
  },

  emits: [
    /**
     * Emitted on option item select
     */
    "update:selectedItems",
  ],

  computed: {
    optionsEmpty() {
      return !Array.isArray(this.options) || this.options.length <= 0;
    },
  },

  methods: {
    onSelect(value) {
      const newSelectedItems = this.isOptionSelected(value)
        ? this.selectedItems.filter((item) => item !== value)
        : [...this.selectedItems, value];

      this.$emit("update:selectedItems", [...newSelectedItems]);
    },

    isOptionSelected(option) {
      return this.selectedItems.includes(option);
    },
  },
};
</script>

<style scoped>
.multi-button__buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 20px;
}

.button {
  font-weight: 500;
  padding: 0px;
  background: none;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  padding: 3px;
  color: var(--secondary-color);
  font-size: 18px;
  border: none;
}

.button__inner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f9fafb;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid var(--secondary-color);
}

.button--active {
  background-color: #f9fafb;
}
</style>
