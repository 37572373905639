<template>
    <GoogleMap
        ref="mapRef"
        api-key="AIzaSyBaLS1XphPQcrOH5PU3dLpoQlYtAP53sd8"
        style="width: 100%; height: 100%"
        :center="communityCoordinates"
        :zoom="DEFAULT_ZOOM"
        map-type-id="terrain"
        disable-default-ui
    >
        <ButtonIcon
            v-if="!mobileView"
            class="map-expand-button"
            symbol="maximize-2"
            @on-click="onMapExpand"
            bg-color="#150E20"
        />
        <Marker
            :options="{
                position: communityCoordinates,
                icon: {
                    url: 'markers/map-pin_home.svg',
                },
            }"
        >
            <InfoWindow>
                <div class="info-window">
                    <div class="info-window__name">
                        {{ community.c_community_name }}
                    </div>
                    <div class="info-window__address">
                        {{ community.c_address }}
                    </div>
                </div>
            </InfoWindow>
        </Marker>
        <Marker
                v-for="location in locations"
                :key="location.place_id"
                :options="{
                    position: location.position,
                    icon: {
                        url: location.icon,
                    },
                }"
            >
                <InfoWindow>
                    <div class="info-window">
                        <div class="info-window__name">
                            {{ location.name }}
                        </div>
                        <div class="info-window__rating-wrapper">
                            <vue-feather
                                type="star"
                                size="16"
                                stroke="#FDB022"
                                fill="#FDB022"
                            />
                            <div class="info-window__rating">
                                {{ location.rating.toFixed(1) }}
                            </div>
                            <div class="info-window__reviews">
                                {{ `(${location.reviews} reviews)` }}
                            </div>
                        </div>
                        <div class="info-window__address">
                            {{ location.vicinity }}
                        </div>
                    </div>
                </InfoWindow>
        </Marker>
    </GoogleMap>
</template>

<script>
import { GoogleMap, Marker, InfoWindow } from 'vue3-google-map';
import ButtonIcon from "@/components/Shared/ButtonIcon.vue";

import { mapGetters } from 'vuex';
import feather from "feather-icons";
import MainService from '@/services/main.service';


const DEFAULT_ZOOM = 14;
const DEFAULT_RADIUS = 7000;

export default {
    components: {
        GoogleMap,
        Marker,
        InfoWindow,
        ButtonIcon,
    },

    props: {
        isMapView: {
            type: Boolean,
            required: true,
        },

        category: {
            type: String,
            required: true,
        },
    
        categories: {
            type: Array,
            default: () => [],
        },

        locations: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            map: null,
            api: null,
            placesService: null,
            distanceMatrixService: null,
            DEFAULT_ZOOM,
            windowWidth: window.innerWidth,
        };
    },

    computed: {
        ...mapGetters({
            community: 'main/getCommunity',
        }),

        mobileView() {
            return this.windowWidth <= 720;
        },

        mapRef() {
            return this.$refs.mapRef;
        },

        communityCoordinates() {
            return {
                lat: this.community.latitude,
                lng: this.community.longitude,
            };
        },
    },

    watch: {
        category: {
            handler(value) {
                this.loadLocations(value);
            },
        },

        isMapView: {
            handler(value) {
                if (value) {
                    this.$nextTick(() => this.setMapBounds(this.locations));
                }
            },
        },
    },

    mounted() {
        feather.replace();
        window.addEventListener('resize', this.onResizeWindow);
        this.$watch('$refs.mapRef.ready', ready => {
            if (ready) {
                this.init();
            }
        });
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.onResizeWindow);
    },

    methods: {
        init() {
            this.map = this.$refs.mapRef.map;
            this.api = this.$refs.mapRef.api;
            this.placesService = new this.api.places.PlacesService(this.map);
            this.distanceMatrixService = new this.api.DistanceMatrixService();
            this.loadLocations(this.category);
        },
    
        async loadLocations(category) {
            const locations = await this.searchNearby({
                location: this.communityCoordinates,
                radius: DEFAULT_RADIUS,
                type: [category],
            });

            const formattedLocations = this.formatLocations(locations, category);
            if (formattedLocations) {
                this.$emit('update:locations', formattedLocations);
                if (this.isMapView) {
                    this.setMapBounds(formattedLocations);
                }
            }
        },

        formatLocations(locations = [], category) {
            if (this.category !== category) {
                return;
            }

            const icon = this.categories.find(item => item.key === this.category)?.icon;

            const formattedLocations = locations.map(location => {
                return {
                    ...location,
                    name: location.name,
                    position: {
                        lat: location.geometry.location.lat(),
                        lng: location.geometry.location.lng(),
                    },
                    rating: location.rating ?? 0,
                    vicinity: location.vicinity,
                    reviews: location.user_ratings_total ?? 0,
                    icon: `markers/${icon}`,
                };
            });

            return formattedLocations;
        },

        setMapBounds(locations = []) {
            const bounds = new this.$refs.mapRef.api.LatLngBounds();

            bounds.extend(this.communityCoordinates);
            locations.forEach(location => bounds.extend(location.position));

            this.map.fitBounds(bounds);
        },

        searchNearby(request) {
            const locations = [];
            return new Promise(resolve => {
                this.placesService.nearbySearch(request, (results, status, pagination) => {
                    if (status !== 'OK' || !results) {
                        console.error("Problem loading POIs");
                        resolve(locations);
                    }
                    locations.push(...results);
                    resolve(locations);
                    if (pagination && pagination.hasNextPage) {
                        pagination.nextPage();
                    } else {
                        resolve(locations);
                    }
                });
            });
        },

        getDistanceMatrix(request) {
            return new Promise((resolve, reject) => {
                this.distanceMatrixService.getDistanceMatrix(request, (response, status) => {
                    if (status === 'OK') {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                });
            });
        },

        onWindowResize() {
            this.windowWidth = window.innerWidth;
        },

        onMapExpand() {
            MainService.expandAppWindow();
        },
    },
}
</script>

<style scoped>
.map-expand-button {
  position: absolute;
  top: 10px;
  right: 24px;
}

.info-window {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.info-window__name {
    text-align: start;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: #101828;
}
.info-window__rating-wrapper {
    display: flex;
    gap: 8px;
}
.info-window__rating {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: #101828;
}
.info-window__reviews {
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: #101828;
}

.info-window__address {
    text-align: start;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #101828;
    letter-spacing: 0.25px;
}
</style>