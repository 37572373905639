export default {
  persona_select_button_privacy_policy: "Privacy Policy",
  persona_select_button_browser_compatibility:
    "Browser Compatibility",
  persona_select_language: "Select your Language",
  persona_select_button_next: "Next",
  persona_select_agent: "Select your agent",
  persona_select_button_begin: "Begin",
  persona_select_button_back: "Back",
  persona_select_step: "OF",
};
