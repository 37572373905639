
import actions from "./actions";
import intro_modal from "./intro_modal";
import video_window from "./video_window";
import schedule_tour from "./schedule_tour";

export default {
  actions,
  intro_modal,
  video_window,
  schedule_tour,

  chat: {
    history_menu_top_card: "Menú",
  },
  neighborhood: {
    sort_by: "Ordenar por",
    switch_to: {
      list: "pasar a la vista de lista",
      map: "pasar a la vista de mapa",
    },
  },
  floorplans: {
    availability: {
      title: "Disponibilidad",
      description: "Unidades actuales en función de las preferencias",
    },
    form_label_square_feet: "Metros Cuadrados (Opcional)",
  },
  preference: {
    title: "Seleccionar preferencia",
  },
  bedrooms: {
    long: {
      1: "Un dormitorio",
      2: "Dos dormitorios",
      3: "Tres dormitorios",
      4: "Cuatro dormitorios",
      5: "Cinco dormitorios",
      6: "Seis dormitorios",
      7: "Siete dormitorios",
      8: "Ocho dormitorios",
      9: "Nueve dormitorios",
      10: "Diez dormitorios",
    },
  },
  form_one_left_schedule_tour: "Necesitamos algunos datos basicos",
  date_top_right_tour: "Selecciona una fecha",
  time_not_found: "No tiempos disponibles",
  time_top_right_tour: "Selecciona una hora",
  menu_headline: "¿Qué quieres explorar?",
  contact_form_top_card: "Contacto",
  button_add_feedback: "comentarios",
  button_details: "añadir detalles",
  button_add_to_calendar: "calendario",
  results: "Resultados",
};