import { isValidNumber } from "./number";

/**
 * Filter floorplans by bedrooms count
 * @param {array} floorplans
 * @param {array} bedrooms
 * @returns floorplans filtered by bedrooms
 */
export function filterFloorplansByBedrooms(floorplans = [], bedrooms = []) {
  return floorplans.filter((floorplan) => bedrooms.includes(floorplan.beds));
}

/**
 * Generate short label for bedroom count
 * @param {number | string} beds bedrooms count
 * @returns short label for bedroom count
 */
export function getBedroomLabel(beds = 0) {
  if (!isValidNumber(beds)) {
    return "";
  }

  if (beds == 0) {
    return "S";
  }

  return `${beds}br`;
}
